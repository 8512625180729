
@function image-url($value) {
	@return url('#{$image-path}#{$value}');
}

@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}

@mixin font-size($values) {
	$px: ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / 10 + rem));
			}

			@if $unit == "rem" {
				$px : append($px,  ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		font-size: $px;
	} @else {
		font-size: $px;
		font-size: $rem;
	}
}

@mixin line-height($values) {
	$px: ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / 10 + rem));
			}

			@if $unit == "rem" {
				$px : append($px,  ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		line-height: $px;
	} @else {
		line-height: $px;
		line-height: $rem;
	}
}

@mixin transition-fix() {
	backface-visibility: hidden;
	transform: rotate(0);
}