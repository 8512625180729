html, body {
	height: 100%;
	font-smoothing: antialiased;
	width: 100%;
}
body {
	background: $color-brand-white;
	max-width: 1920px;
	min-width: 320px;
	margin:0;
	padding:0;
}
