/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Clearfix */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}

html {
  font-size: 58%;
}

body {
  color: #555555;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-smooth: antialiased;
}

*:not(html) {
  font-size: 16px;
  font-size: 1.6rem;
}

h1 {
  color: #222222;
  font-size: 30px;
  font-size: 3rem;
  font-weight: 400;
  line-height: 36px;
  line-height: 3.6rem;
  padding-bottom: 40px;
}

h2 {
  color: #222222;
  font-size: 38px;
  font-size: 3.8rem;
  font-weight: 800;
  line-height: 46px;
  line-height: 4.6rem;
  padding-bottom: 40px;
}

h3 {
  color: #aaaaaa;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 21px;
  line-height: 2.1rem;
  padding-bottom: 5px;
}
h3.black {
  color: #222222;
}

h4 {
  color: #555555;
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 32px;
  line-height: 3.2rem;
  padding-bottom: 20px;
}

h5 {
  color: #777777;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
}

h6 {
  color: #777777;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
}

p {
  font-size: 20px;
  font-size: 2rem;
  line-height: 28px;
  line-height: 2.8rem;
}
p.small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 24px;
  line-height: 2.4rem;
}
p.medium {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 26px;
  line-height: 2.6rem;
}
p.green {
  color: #0eba72;
}
p.white {
  color: #fcfcfc;
}
p.black {
  color: #222222;
}

a {
  color: #0eba72;
  font-size: inherit;
  font-weight: 700;
  transition: color 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.link {
  display: inline-block;
  position: relative;
  transition: padding 0.3s;
}
.link--black {
  color: #222222;
}
.link--right {
  padding-right: 30px;
}
.link--left {
  padding-left: 35px;
}
.link__arrow {
  bottom: 0;
  height: 24px;
  position: absolute;
  right: 0;
  transition: left 0.3s;
  width: 24px;
}
.link__arrow--left {
  left: 5px;
  right: auto;
}
.link__arrow--green svg {
  fill: #0eba72;
  height: 100%;
  width: 100%;
}
.link__arrow--black svg {
  fill: #222222;
  height: 100%;
  width: 100%;
}

span {
  font-size: inherit;
}

em, i {
  font-style: italic;
}

b, strong {
  font-size: inherit;
  font-weight: 600;
}

ul {
  color: #555555;
}
ul li {
  box-sizing: border-box;
  font-size: 20px;
  font-size: 2rem;
  line-height: 28px;
  line-height: 2.8rem;
  list-style-position: inside;
  list-style-type: disc;
  padding: 0 0 20px 35px;
  text-indent: -35px;
}
ul li:last-child {
  padding: 0 0 0 35px;
}

.br {
  padding-bottom: 30px;
}
.br--mini {
  padding-bottom: 12px;
}
.br--minitop {
  padding-top: 12px;
}
.br--small {
  padding-bottom: 25px;
}
.br--large {
  padding-bottom: 60px;
}
.br--largetop {
  padding-top: 60px;
}

.border--bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

img {
  border-radius: 10px;
  width: 100%;
}
img.stroke {
  border: 1px solid rgba(207, 218, 223, 0.8);
  box-sizing: border-box;
}

video {
  border-radius: 10px;
  width: 100%;
}

form {
  padding-top: 5%;
}

input[type=text], input[type=email], input[type=number], textarea {
  background: none;
  border-bottom: 2px solid #222222;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-top: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 28px;
  line-height: 2.8rem;
  margin-bottom: 40px;
  transition: all 0.3s;
  width: 100%;
}
input[type=text]::placeholder, input[type=email]::placeholder, input[type=number]::placeholder, textarea::placeholder {
  color: #555555;
}

input[type=number] {
  max-width: 200px;
}

textarea {
  border: 2px solid #222222;
  border-radius: 5px;
  height: 120px;
  padding: 15px;
  resize: vertical;
}

input:focus {
  border-bottom: 2px solid #2682ed;
  outline: none;
}

textarea:focus {
  border: 2px solid #2682ed;
  outline: none;
}

.button {
  background-color: #0eba72;
  border: none;
  border-radius: 5px;
  color: #fcfcfc;
  cursor: pointer;
  display: block;
  font-weight: 700;
  outline: none;
  padding: 15px 64px 15px 40px;
  transition: padding 0.4s;
}
.button__icon {
  position: relative;
}
.button__arrow {
  height: 24px;
  margin-top: -12px;
  position: absolute;
  right: 32px;
  top: 50%;
  width: 24px;
}
.button__arrow svg {
  fill: #fcfcfc;
  height: 100%;
  width: 100%;
}

html, body {
  height: 100%;
  font-smoothing: antialiased;
  width: 100%;
}

body {
  background: #fcfcfc;
  max-width: 1920px;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

.header {
  position: relative;
  width: 100%;
}
.header__fill {
  height: 100%;
  min-height: 660px;
}

.nav {
  display: block;
  box-sizing: border-box;
  padding: 100px 10% 0 10%;
}
.nav__contain {
  position: relative;
  width: 100%;
}
.nav__logo {
  bottom: 0;
  left: 0;
  max-width: 60px;
  position: absolute;
  width: 100%;
}
.nav__logo svg {
  fill: #222222;
}
.nav__menu {
  bottom: 0;
  position: absolute;
  right: 0;
  text-align: right;
}
.nav__item {
  color: #222222;
  font-weight: 400;
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 24px;
  line-height: 2.4rem;
  padding: 10px 20px;
  transition: color 0.3s;
}
.nav__item:first-child {
  padding: 10px 20px 10px 0px;
}
.nav__item:last-child {
  padding: 10px 0 10px 20px;
}
.nav__icon {
  display: inline-block;
  height: 22px;
  padding-right: 5px;
  width: 22px;
  vertical-align: top;
}
.nav__icon svg {
  fill: #222222;
  height: 100%;
  transition: fill 0.3s;
  width: 100%;
}

.hero {
  bottom: 8%;
  left: 0;
  position: absolute;
  width: 100%;
}
.hero__wrapper {
  box-sizing: border-box;
  padding: 0 10%;
  width: 100%;
}
.hero__blurb {
  max-width: 800px;
}
.hero__foot {
  max-width: 900px;
  padding-top: 8%;
}
.hero__social {
  font-size: 0;
  padding-top: 5%;
}
.hero__item {
  display: inline-block;
  padding: 0 15px;
  vertical-align: top;
}
.hero__item:first-child {
  padding: 0 15px 0 0;
}
.hero__item:last-child {
  padding: 0 0 0 15px;
}
.hero__icon {
  height: 24px;
  width: 24px;
}
.hero__icon svg {
  fill: #222222;
  height: 100%;
  transition: fill 0.3s;
  width: 100%;
}

.work {
  box-sizing: border-box;
  font-size: 0;
  padding: 4%;
}
.work__box {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.work__box--small {
  width: 40%;
}
.work__box--large {
  width: 60%;
}
.work__text {
  box-sizing: border-box;
  padding-right: 4%;
}
.work__gap {
  padding-bottom: 10%;
}
.work__image {
  padding: 8% 0;
  position: relative;
}
.work__curtain {
  background-color: #eff2f5;
  height: 100%;
  position: absolute;
  right: 8%;
  top: 0;
  width: 65%;
  z-index: -1;
}

.experience {
  box-sizing: border-box;
  padding: 8% 12%;
}
.experience__wrapper {
  padding-top: 4%;
  font-size: 0;
}
.experience__brands {
  box-sizing: border-box;
  display: inline-block;
  padding: 4% 60px 0 0;
  vertical-align: middle;
  width: 22%;
}
.experience__stryker {
  width: 80%;
}
.experience__microsoft {
  width: 90%;
}
.experience__kelloggs {
  width: 75%;
}
.experience__accenture {
  width: 85%;
}
.experience__campbells {
  width: 70%;
}
.experience__puremichigan {
  width: 90%;
}
.experience__garena {
  width: 80%;
}
.experience__usahockey {
  width: 70%;
}

.contact {
  background-color: #eff2f5;
  box-sizing: border-box;
  padding: 8% 12%;
}
.contact__feedback {
  padding: 10px 0;
}
.contact__feedback--success {
  color: #0eba72;
  font-weight: 700;
}
.contact__feedback--error {
  color: #2682ed;
  font-weight: 700;
}
.contact__prev {
  left: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
}

.form {
  width: 80%;
}

.blog {
  box-sizing: border-box;
  font-size: 0;
  padding: 50px 10% 10% 10%;
  position: relative;
}
.blog__sidebar {
  box-sizing: border-box;
  display: inline-block;
  padding-right: 5%;
  padding-top: 120px;
  vertical-align: top;
  width: 25%;
}
.blog__back {
  display: inline-block;
  vertical-align: top;
}
.blog__arrow {
  display: none;
  height: 24px;
  width: 24px;
}
.blog__list {
  display: inline-block;
  vertical-align: top;
  width: 75%;
}

.subnav {
  background-color: #fcfcfc;
  font-size: 0;
}
.subnav__position {
  left: 0;
  height: 150px;
  position: fixed;
  top: 0;
  width: 0;
}
.subnav__sticky {
  box-sizing: border-box;
  margin-left: 0;
  padding-right: 4%;
  position: fixed;
  top: 120px;
  width: 20%;
  z-index: 99;
}
.subnav__list {
  padding-top: 65px;
  width: 100%;
}
.subnav__item {
  color: #222222;
  display: block;
  font-weight: 400;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 28px;
  line-height: 2.8rem;
  padding: 10px 10px;
  transition: color 0.3s;
}
.subnav__item--active {
  color: #0eba72;
}
.subnav__burger {
  display: none;
}

.case__head {
  padding-top: 120px;
}
.case--img {
  border-radius: 8px;
}
.case__subhead {
  padding-top: 70px;
}
.case__step {
  font-weight: 600;
}
.case__step--orange {
  color: #ff4a1f;
}
.case__status {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
}
.case__status a {
  border-bottom: 2px solid #f3465a;
  color: #f3465a;
  font-weight: 600;
  padding-bottom: 1px;
}
.case__status--red {
  background-color: #ffeaec;
  color: #f3465a;
  border: 2px solid #F3465A;
}

.highlight {
  display: table;
  padding-bottom: 20px;
  width: 100%;
}
.highlight__pad {
  padding-bottom: 60px;
}
.highlight__padmed {
  padding-bottom: 30px;
}
.highlight__last {
  padding-bottom: 0;
}
.highlight__bin {
  background-color: #eff2f5;
  border-radius: 10px;
  box-sizing: border-box;
  display: table-cell;
  padding: 20px;
  position: relative;
  width: 32%;
}
.highlight__bin p {
  font-size: 20px;
  font-size: 2rem;
  line-height: 28px;
  line-height: 2.8rem;
}
.highlight__bin__small {
  padding: 10px;
}
.highlight__bin--red {
  background-color: #FCE8E3;
  border: 1px solid #FF4A1F;
  padding-left: 54px;
}
.highlight__bin--green {
  background-color: #E7FCF3;
  border: 1px solid #0EBA72;
  padding-left: 54px;
}
.highlight__bin--teal {
  background-color: #e2f0e8;
}
.highlight__space {
  display: table-cell;
  width: 2%;
}
.highlight__number {
  border-radius: 50%;
  box-sizing: border-box;
  color: fff;
  display: inline-block;
  height: 25px;
  left: 18px;
  padding-top: 2px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}
.highlight__number p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.highlight__number--red {
  background-color: #FF4A1F;
}
.highlight__number--green {
  background-color: #0EBA72;
}
.highlight__mobile {
  display: none;
}

.extend {
  padding-bottom: 500px;
}

.hook {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}
.hook__box {
  opacity: 0;
}
.hook__image {
  opacity: 0;
}
.hook__curtain {
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-in-var {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fade__in {
  animation-name: fade-in;
}
.fade__in--up {
  animation-name: fade-in-up;
}
.fade__in--left {
  animation-name: fade-in-left;
}
.fade__in--var {
  animation-name: fade-in-var;
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
}
.animated__long {
  animation-duration: 5s;
  animation-fill-mode: both;
}
.animated__short {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animated__infinite {
  animation-iteration-count: infinite;
}

.delay__mini {
  animation-delay: 0.1s;
}
.delay__one {
  animation-delay: 0.4s;
}
.delay__two {
  animation-delay: 0.8s;
}
.delay__three {
  animation-delay: 1s;
}

.footer {
  box-sizing: border-box;
  background-color: #eff2f5;
  padding: 0 12% 10% 12%;
}
.footer__copy {
  padding-top: 40px;
}

@media (hover: hover) {
  .button:hover {
    padding: 15px 72px 15px 40px;
  }
  .nav__item:hover {
    color: #0eba72;
  }
  .nav__item:hover svg {
    fill: #0eba72;
  }
  .hero__icon--red svg:hover {
    fill: #db4437;
  }
  .hero__icon--pink svg:hover {
    fill: #ea4c89;
  }
  .hero__icon--blue-d svg:hover {
    fill: #0e76a8;
  }
  .hero__icon--blue-l svg:hover {
    fill: #00aced;
  }
  .link--right:hover {
    padding-right: 35px;
  }
  .link--left:hover .link__arrow--left {
    left: 0;
  }
  .subnav__item:hover {
    color: #0eba72;
  }
  .case__status a:hover {
    border-bottom: 2px solid #c33b4b;
    color: #c33b4b;
  }
}
@media (max-width: 1400px) {
  .experience__brands {
    width: 25%;
  }
}
@media (max-width: 1200px) {
  h2 {
    font-size: 34px;
    font-size: 3.4rem;
    padding-bottom: 35px;
  }
  .experience__brands {
    width: 30%;
  }
}
@media (max-width: 1100px) {
  .blog {
    box-sizing: border-box;
    font-size: 0;
    padding: 30px 10% 10% 10%;
  }
  .blog__sidebar {
    height: 60px;
    padding-right: 0%;
    padding-top: 0;
    width: 100%;
  }
  .blog__back {
    padding-top: 10px;
    width: 20%;
  }
  .blog__list {
    width: 100%;
  }
  .subnav {
    box-sizing: border-box;
    left: 0;
    padding: 15px 10%;
    position: absolute;
    top: 30px;
    width: 100%;
  }
  .subnav__position {
    height: 130px;
  }
  .subnav__sticky {
    border-bottom: 1px solid whitesmoke;
    left: 0;
    position: fixed;
    top: 0;
  }
  .subnav__list {
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
    padding-top: 0;
    width: 80%;
  }
  .subnav__item {
    display: inline-block;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 22px;
    line-height: 2.2rem;
    padding: 10px 10px;
  }
  .subnav__item:last-child {
    padding: 10px 0 10px 10px;
  }
}
@media (max-width: 1000px) {
  .work__arrow {
    bottom: -1px;
  }
  .experience__brands {
    padding: 7% 60px 0 0;
    width: 33%;
  }
  .highlight__bin p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 24px;
    line-height: 2.4rem;
  }
  .highlight__bin--red {
    padding-left: 52px;
  }
  .highlight__bin--green {
    padding-left: 52px;
  }
  .highlight__number {
    top: 8px;
  }
}
@media (max-width: 960px) {
  form {
    padding-top: 7%;
  }
  .work {
    padding: 6% 10%;
    font-size: 0;
  }
  .work__box {
    display: block;
  }
  .work__box--small {
    padding-bottom: 7%;
    width: 100%;
  }
  .work__box--large {
    width: 100%;
  }
  .work__text {
    padding-right: 0%;
  }
  .work__gap {
    padding-bottom: 4%;
  }
  .work__image {
    padding: 0;
  }
  .work__curtain {
    display: none;
  }
  .experience {
    padding: 8% 10%;
  }
  .contact {
    padding: 8% 10%;
  }
  .form {
    width: 100%;
  }
  .footer {
    padding: 0 10% 10% 10%;
  }
  @keyframes fade-in-var {
    from {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .delay__mini {
    animation-delay: 0s;
  }
}
@media (max-width: 800px) {
  .experience__brands {
    padding: 8% 60px 0 0;
    width: 38%;
  }
}
@media (max-width: 768px) {
  .br {
    padding-bottom: 30px;
  }
  .br--small {
    padding-bottom: 25px;
  }
  .work {
    padding: 9% 6% 9% 6%;
  }
  .nav {
    padding: 100px 6% 0 6%;
  }
  .hero__wrapper {
    padding: 0 6%;
  }
  .experience {
    padding: 12% 6%;
  }
  .contact {
    padding: 12% 6%;
  }
  .blog {
    padding: 30px 6% 10% 6%;
  }
  .subnav {
    padding: 15px 6%;
  }
  .case__head {
    padding-top: 100px;
  }
  .case__subhead {
    padding-top: 60px;
  }
  .highlight__bin {
    padding: 20px 20px;
  }
  .highlight__bin__small {
    padding: 12px;
  }
  .highlight__bin--red {
    padding-left: 50px;
  }
  .highlight__bin--green {
    padding-left: 50px;
  }
  .highlight__number {
    top: 10px;
  }
  .highlight__space {
    width: 2%;
  }
  .footer {
    padding: 0 6% 15% 6%;
  }
}
@media (max-width: 660px) {
  h2 {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 40px;
    line-height: 4rem;
  }
  h4 {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 28px;
    line-height: 2.8rem;
  }
  p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 26px;
    line-height: 2.6rem;
  }
  ul li {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 26px;
    line-height: 2.6rem;
    padding: 0 0 20px 28px;
    text-indent: -28px;
  }
  ul li:last-child {
    padding: 0 0 0 28px;
  }
  form {
    padding-top: 6%;
  }
  input[type=text], input[type=email], input[type=number], textarea {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 26px;
    line-height: 2.6rem;
  }
  .experience__brands {
    padding: 8% 60px 0 0;
    width: 50%;
  }
  .blog__back {
    width: 10%;
  }
  .blog__arrow {
    display: inline-block;
  }
  .subnav__list {
    width: 90%;
  }
  .hide {
    display: none;
  }
}
@media (max-width: 600px) {
  .nav {
    padding: 80px 6% 0 6%;
  }
  .nav__contain {
    height: 80px;
  }
  .nav__logo {
    bottom: auto;
    top: 0;
  }
  .nav__menu {
    bottom: 0;
    left: 0;
    right: auto;
    text-align: left;
  }
  .nav__item {
    padding: 10px 16px;
  }
  .blog {
    padding: 20px 6% 20% 6%;
  }
  .blog__sidebar {
    height: 50px;
  }
  .blog__back {
    width: 50%;
    padding: 0;
  }
  .subnav {
    border-bottom: 1px solid whitesmoke;
    top: 20px;
  }
  .subnav__position {
    height: 180px;
  }
  .subnav__sticky {
    top: 0;
  }
  .subnav__list {
    background-color: #fcfcfc;
    box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;
    border-left: 1px solid whitesmoke;
    border-radius: 0 0 0 20px;
    display: none;
    padding: 0 6%;
    position: absolute;
    right: 0;
    top: 56px;
    width: 50%;
  }
  .subnav__show {
    display: inline-block;
  }
  .subnav__item {
    padding: 15px 0;
    width: 100%;
  }
  .subnav__item:first-child {
    padding: 30px 0 15px 0;
  }
  .subnav__item:last-child {
    padding: 15px 0 30px 0;
  }
  .subnav__burger {
    width: 50%;
    display: inline-block;
    text-align: right;
    vertical-align: middle;
  }
  .subnav__icon {
    cursor: pointer;
    display: inline-block;
    height: 24px;
    width: 24px;
  }
  .subnav__icon svg {
    height: 20px;
    fill: #222222;
    padding: 2px;
    width: 20px;
  }
  .case__head {
    padding-top: 90px;
  }
  .case__subhead {
    padding-top: 50px;
  }
  .highlight {
    display: none;
    font-size: 0;
  }
  .highlight__bin {
    display: block;
    width: 100%;
  }
  .highlight__space {
    height: 10px;
    display: block;
    width: 0%;
  }
  .highlight__mobile {
    display: block;
  }
  .highlight__visible {
    display: block;
  }
  .fade__in--down {
    animation-name: fade-in-down;
  }
  .animated__subnav {
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
}
@media (max-width: 500px) {
  h1 {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 34px;
    line-height: 3.4rem;
    padding-bottom: 30px;
  }
  form {
    padding-top: 12%;
  }
  .work {
    padding: 12% 5%;
    font-size: 0;
  }
  .work__box {
    display: block;
  }
  .work__box--small {
    padding-bottom: 10%;
  }
  .nav {
    padding: 60px 5% 0 5%;
  }
  .hero__wrapper {
    padding: 0 5%;
  }
  .hero__foot {
    padding-top: 12%;
  }
  .hero__social {
    padding-top: 8%;
  }
  .experience {
    padding: 15% 5%;
  }
  .experience__brands {
    padding: 12% 20px 0 0;
  }
  .contact {
    padding: 15% 5%;
  }
  .blog {
    padding: 20px 5% 20% 5%;
  }
  .blog__back {
    width: 40%;
  }
  .subnav {
    padding: 15px 5%;
  }
  .subnav__position {
    height: 160px;
  }
  .subnav__list {
    width: 60%;
  }
  .subnav__burger {
    width: 60%;
  }
  .footer {
    padding: 0 5% 20% 5%;
  }
}
@media (max-width: 375px) {
  .nav__item {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 24px;
    line-height: 2.4rem;
    padding: 10px;
  }
  .experience__brands {
    padding: 15% 20px 0 0;
  }
  .blog__back {
    width: 30%;
  }
  .subnav__position {
    height: 160px;
  }
  .subnav__list {
    width: 70%;
  }
  .subnav__burger {
    width: 70%;
  }
}