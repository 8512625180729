
@media (hover: hover){ 
	.button{
		&:hover{
			padding: 15px 72px 15px 40px;
		}
	}
	.nav{
		&__item{
			&:hover{
				color: $color-brand-green;
				svg{
					fill: $color-brand-green;
				}
			}
		}
	}
	.hero{
		&__icon{
			&--red{
				svg{
					&:hover{
						fill: $color-social-gmail;
					}
				}
			
			}
			&--pink{
				svg{
					&:hover{
						fill: $color-social-dribbble;
					}
				}
			}
			&--blue-d{
				svg{
					&:hover{
						fill: $color-social-linkedin;
					}
				}
			}
			&--blue-l{
				svg{
					&:hover{
						fill: $color-social-twitter;
					}
				}
			}
		}
	}
	.link{
		&--right{
			&:hover{
				padding-right: 35px;
			}
		}
		&--left{
			&:hover{
				.link{
					&__arrow{
						&--left{
							left: 0;
						}
					}
				}
			}
		}
	}
	.subnav{
		&__item{
			&:hover{
				color: $color-brand-green;
			}
		}
	}
	.case{
		&__status{
			a{
				&:hover{
					border-bottom: 2px solid rgba(#C33B4B,1);
					color: rgba(#C33B4B,1);;
				}
			}
		}
	}
}

@media (max-width: 1400px){
	.experience{
		&__brands{
			width: 25%;
		}
	}
}

@media (max-width: 1200px){
	h2 {
		@include font-size(34px);
		padding-bottom: 35px;
	}
	.experience{
		&__brands{
			width: 30%;
		}
	}
}

@media (max-width: 1100px){
	.blog{
		box-sizing: border-box;
		font-size: 0;
		padding: 30px 10% 10% 10%;
		&__sidebar{
			height: 60px;
			padding-right: 0%;
			padding-top: 0;
			width: 100%;
		}
		&__back{
			padding-top: 10px;
			width: 20%;
		}
		&__list{
			width: 100%;
		}
	}
	.subnav{
		box-sizing: border-box;
		left: 0;
		padding: 15px 10%;
		position: absolute;
		top: 30px;
		width: 100%;
		&__position{
			height: 130px;
		}
		&__sticky{
			border-bottom: 1px solid $color-brand-grayf;
			left: 0;
			position: fixed;
			top: 0;
		}
		&__list{
			box-sizing: border-box;
			display: inline-block;
			text-align: right;
			padding-top: 0;
			width: 80%;
		}
		&__item{
			display: inline-block;
			@include font-size(16px);
			@include line-height(22px);
			padding: 10px 10px;
			&:last-child{
				padding: 10px 0 10px 10px;
			}
		}
	}
}

@media (max-width: 1000px){
	.work{
		&__arrow{
			bottom: -1px;
		}
	}
	.experience{
		&__brands{
			padding: 7% 60px 0 0;
			width: 33%;
		}
	}
	.highlight{
		&__bin{
			p{
				@include font-size(18px);
				@include line-height(24px);
			}
			&--red{
				padding-left: 52px;
			}
			&--green{
				padding-left: 52px;
			}
		}
		&__number{
			top: 8px;
		}	
	}
}

@media (max-width: 960px){
	form{
		padding-top: 7%;
	}
	.work{
		padding: 6% 10%;
		font-size: 0;
		&__box{
			display: block;
			&--small{
				padding-bottom: 7%;
				width: 100%;
			}
			&--large{
				width: 100%;
			}
		}
		&__text{
			padding-right: 0%;
		}
		&__gap{
			padding-bottom: 4%;
		}
		&__image{
			padding: 0;
		}
		&__curtain{
			display: none;
		}
	}
	.experience{
		padding: 8% 10%;
	}
	.contact{
		padding: 8% 10%;
	}
	.form{
		width: 100%;
	}
	.footer {
		padding: 0 10% 10% 10%;
	}	
	@keyframes fade-in-var {
		from {
			opacity: 0;
			transform: translate3d(0, 0, 0);
		}
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	.delay {
		&__mini{
			animation-delay: 0s;
		}
	}
}

@media (max-width: 800px){
	.experience{
		&__brands{
			padding: 8% 60px 0 0;
			width: 38%;
		}
	}
}

@media (max-width: 768px){
	.br{
		padding-bottom: 30px;
		&--small{
			padding-bottom: 25px;
		}
	}
	.work{
		padding: 9% 6% 9% 6%;
	}
	.nav{
		padding: 100px 6% 0 6%;
	}
	.hero{
	    &__wrapper{
			padding: 0 6%;
		}
	}
	.experience{
		padding: 12% 6%;
	}
	.contact{
		padding: 12% 6%;
	}
	.blog{
		padding: 30px 6% 10% 6%;
	}
	.subnav{
		padding: 15px 6%;
	}
	.case{
		&__head{
			padding-top: 100px;
		}
		&__subhead{
			padding-top: 60px;
		}
	}
	.highlight{
		&__bin{
			padding: 20px 20px;
			&__small{
				padding: 12px;
			}
			&--red{
				padding-left: 50px;
			}
			&--green{
				padding-left: 50px;
			}
		}
		&__number{
			top: 10px;
		}	
		&__space{
			width: 2%;
		}
	}
	.footer {
		padding: 0 6% 15% 6%;
	}
}

@media (max-width: 660px){
	h2 {
		@include font-size(32px);
		@include line-height(40px);
	}
	h4 {
		@include font-size(22px);
		@include line-height(28px);
	}
	p{
		@include font-size(18px);
		@include line-height(26px);
	}
	ul{
		li{
			@include font-size(18px);
			@include line-height(26px);
			padding: 0 0 20px 28px;
			text-indent: -28px;
			&:last-child{
				padding: 0 0 0 28px;
			}
		}
	}
	form{
		padding-top: 6%;
	}
	input[type=text], input[type=email], input[type=number], textarea { 
		@include font-size(18px);
		@include line-height(26px);
	}
	.experience{
		&__brands{
			padding: 8% 60px 0 0;
			width: 50%;
		}
	}
	.blog{
		&__back{
			width: 10%;
		}
		&__arrow{
			display: inline-block;
		}
	}
	.subnav{
		&__list{
			width: 90%;
		}
	}
	.hide{
		display: none;
	}
}

@media (max-width: 600px){
	.nav{
		padding: 80px 6% 0 6%;
		&__contain{
			height: 80px;
		}
		&__logo{
			bottom: auto;
			top: 0;
		}
		&__menu{
			bottom: 0;
			left: 0;
			right: auto;
			text-align: left;
		}
		&__item{
			padding: 10px 16px;
		}
	}	
	.blog{
		padding: 20px 6% 20% 6%;
		&__sidebar{
			height: 50px;
		}
		&__back{
			width: 50%;
			padding: 0;
		}
	}
	.subnav{
		border-bottom: 1px solid $color-brand-grayf;
		top: 20px;
		&__position{
			height: 180px;
		}
		&__sticky{
			top: 0;
		}
		&__list{
			background-color: $color-brand-white;
			box-sizing: border-box;
			border-bottom: 1px solid $color-brand-grayf;
			border-left: 1px solid $color-brand-grayf;
			border-radius: 0 0 0 20px;
			display: none;
			padding: 0 6%;
			position: absolute;
			right: 0;
			top: 56px;
			width: 50%;
		}
		&__show{
			display: inline-block;
		}
		&__item{
			padding: 15px 0;
			width: 100%;
			&:first-child{
				padding: 30px 0 15px 0;
			}
			&:last-child{
				padding: 15px 0 30px 0;
			}
		}
		&__burger{
			width: 50%;
			display: inline-block;
			text-align: right;
			vertical-align: middle;
		}
		&__icon{
			cursor: pointer;
			display: inline-block;
			height: 24px;
			width: 24px;
			svg{
				height: 20px;
				fill: $color-brand-black;
				padding: 2px;
				width: 20px;
			}
		}
	}
	.case{
		&__head{
			padding-top: 90px;
		}
		&__subhead{
			padding-top: 50px;
		}
	}
	.highlight{
		display: none;
		font-size: 0;
		&__bin{
			display: block;
			width: 100%;
		}
		&__space{
			height: 10px;
			display: block;
			width: 0%;
		}
		&__mobile{
			display: block;
		}
		&__visible{
			display: block;
		}
	}
	.fade__in {
		&--down{
			animation-name: fade-in-down;
		}
	}
	.animated {
		&__subnav{
			animation-duration: .3s;
			animation-fill-mode: both;
		}
	}
}	

@media (max-width: 500px){
	h1 {
		@include font-size(28px);
		@include line-height(34px);
		padding-bottom: 30px;
	}
	form{
		padding-top: 12%;
	}
	.work{
		padding: 12% 5%;
		font-size: 0;
		&__box{
			display: block;
			&--small{
				padding-bottom: 10%;
			}
		}
	}
	.nav{
		padding: 60px 5% 0 5%;
	}
	.hero{ 
		&__wrapper{
			padding: 0 5%;
		}
		&__foot{
			padding-top: 12%;
		}
		&__social{
			padding-top: 8%;
		}
	}
	.experience{
		padding: 15% 5%;
		&__brands{
			padding: 12% 20px 0 0;
		}
	}
	.contact{
		padding: 15% 5%;
	}
	.blog{
		padding: 20px 5% 20% 5%;
		&__back{
			width: 40%;
		}
	}
	.subnav{
		padding: 15px 5%;
		&__position{
			height: 160px;
		}
		&__list{
			width: 60%;
		}
		&__burger{
			width: 60%;
		}
	}
	.footer {
		padding: 0 5% 20% 5%;
	}
}

@media (max-width: 375px){
	.nav{
		&__item{
			@include font-size(16px);
			@include line-height(24px);
			padding: 10px;
		}
	}
	.experience{
		&__brands{
			padding: 15% 20px 0 0;
		}
	}
	.blog{
		&__back{
			width: 30%;
		}
	}
	.subnav{
		&__position{
			height: 160px;
		}
		&__list{
			width: 70%;
		}
		&__burger{
			width: 70%;
		}
	}
}



