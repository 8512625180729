@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');

html {
	font-size: 58%;
}
body {
	color: $color-brand-grayd;
	font-family: $font-main;
	font-weight: 400;
	font-smooth: antialiased;
}
// this is a hack to fix Chrome's resizing of the text when opening the developer tools
*:not(html) {
	@include font-size(16px);
}
// elements
h1 {
	color: $color-brand-black;
	@include font-size(30px);
	font-weight: 400;
	@include line-height(36px);
	padding-bottom: 40px;
}
h2 {
	color: $color-brand-black;
	@include font-size(38px);
	font-weight: 800;
	@include line-height(46px);
	padding-bottom: 40px;
}
h3 {
	color: $color-brand-grayl;
	@include font-size(16px);
	font-weight: 700;
	@include line-height(21px);
	padding-bottom: 5px;
	&.black{
		color: $color-brand-black;
	}
}
h4 {
	color: $color-brand-grayd;
	@include font-size(26px);
	font-weight: 500;
	@include line-height(32px);
	padding-bottom:20px;
}
h5 {
	color: rgba(#777,1);
	@include font-size(21px);
	font-weight: 400;
}
h6 {
	color: rgba(#777,1);
	@include font-size(18px);
	font-weight: 400;
}
p {
	@include font-size(20px);
	@include line-height(28px);
	&.small{
		@include font-size(16px);
		@include line-height(24px);
	}
	&.medium{
		@include font-size(18px);
		@include line-height(26px);
	}
	&.green{
		color: $color-brand-green;
	}
	&.white{
		color: $color-brand-white;
	}
	&.black{
		color: $color-brand-black;
	}
}
a {
	color: $color-brand-green;
	font-size: inherit;
	font-weight: 700;
	transition: color 0.3s;
	text-decoration: none;
	cursor: pointer;
}
.link{
	display: inline-block;
	position: relative;
	transition: padding 0.3s;
	&--black{
		color: $color-brand-black;
	}
	&--right{
		padding-right: 30px;
	}
	&--left{
		padding-left: 35px;
	}
	&__arrow{
		bottom: 0;
		height: 24px;
		position: absolute;
		right: 0;
		transition: left 0.3s;
		width: 24px;
		&--left{
			left: 5px;
			right: auto;
		}
		&--green{
			svg{
				fill: $color-brand-green;
				height: 100%;
				width: 100%;
			}
		}
		&--black{
			svg{
				fill: $color-brand-black;
				height: 100%;
				width: 100%;
			}
		}
	}
}
span{
	font-size: inherit;
}
em, i {
	font-style: italic;
}
b, strong {
	font-size: inherit;
	font-weight: 600;
}
ul{
	color: $color-brand-grayd;
	li{
		box-sizing: border-box;
		@include font-size(20px);
		@include line-height(28px);
		list-style-position: inside;
		list-style-type: disc;
		padding: 0 0 20px 35px;
		text-indent: -35px;
		&:last-child{
			padding: 0 0 0 35px;
		}
	}
}
.br{
	padding-bottom: 30px;
	&--mini{
		padding-bottom: 12px;
	}
	&--minitop{
		padding-top: 12px;
	}
	&--small{
		padding-bottom: 25px;
	}
	&--large{
		padding-bottom: 60px;
	}
	&--largetop{
		padding-top: 60px;
	}
}
.border{
	&--bottom{
		border-bottom: 1px solid rgba(#000, 0.10);
	}
}
