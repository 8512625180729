.header{
	position: relative;
	width: 100%;
	&__fill{
		height: 100%;
		min-height: 660px;
	}
}
.nav{
	display: block;
	box-sizing: border-box;
	padding: 100px 10% 0 10%;
	&__contain{
		position: relative;
		width: 100%;
	}
	&__logo{
		bottom: 0;
		left: 0;
		max-width:60px;
		position: absolute;
		width: 100%;
			svg{
				fill: $color-brand-black;
			}
	}
	&__menu{
		bottom: 0;
		position: absolute;
		right: 0;
		text-align: right;
	}
	&__item{
		color: $color-brand-black;
		font-weight: 400;
		@include font-size(19px);
		@include line-height(24px);
		padding: 10px 20px;
		transition: color 0.3s;
		&:first-child{
			padding: 10px 20px 10px 0px;
		}
		&:last-child{
			padding: 10px 0 10px 20px;
		}
	}
	&__icon{
		display: inline-block;
		height: 22px;
		padding-right: 5px;
		width: 22px;
		vertical-align: top;
		svg{
			fill: $color-brand-black;
			height: 100%;
			transition: fill 0.3s;
			width: 100%;
		}
	}
}
.hero{
	bottom: 8%;
	left: 0;
	position: absolute;
	width: 100%;
    &__wrapper{
    	box-sizing: border-box;
		padding: 0 10%;
    	width: 100%;
	}
	&__blurb{
		max-width: 800px;
	}  
	&__foot{
		max-width: 900px;
		padding-top: 8%;
	}
	&__social{
		font-size: 0;
		padding-top: 5%;
	}
	&__item{
		display: inline-block;
		padding: 0 15px;
		vertical-align: top;
		&:first-child{
			padding: 0 15px 0 0;
		}
		&:last-child{
			padding: 0 0 0 15px;
		}
	}
	&__icon{
		height: 24px;
		width: 24px;
		svg{
			fill: $color-brand-black;
			height: 100%;
			transition: fill 0.3s;
			width: 100%;
		}
	}
}
.work{
	box-sizing: border-box;
	font-size: 0;
	padding: 4%;
	&__box{
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		&--small{
			width: 40%;
		}
		&--large{
			width: 60%;
		}
	}
	&__text{
		box-sizing: border-box;
		padding-right: 4%;
	}
	&__gap{
		padding-bottom: 10%;
	}
	&__image{
		padding: 8% 0;
		position: relative;
	}
	&__curtain{
		background-color: $color-brand-coldblue;
		height: 100%;
		position: absolute;
		right: 8%;
		top: 0;
		width: 65%;
		z-index: -1;
	}
}
.experience{
	box-sizing: border-box;
	padding: 8% 12%;
	 &__wrapper{
	 	padding-top: 4%;
    	font-size: 0;
	}
	&__brands{
		box-sizing: border-box;
		display: inline-block;
		padding: 4% 60px 0 0;
		vertical-align: middle;
		width: 22%;
	}
	&__stryker{
		width: 80%;
	}
	&__microsoft{
		width: 90%;
	}
	&__kelloggs{
		width: 75%;
	}
	&__accenture{
		width: 85%;
	}
	&__campbells{
		width: 70%;
	}
	&__puremichigan{
		width: 90%;
	}
	&__garena{
		width: 80%;
	}
	&__usahockey{
		width: 70%;
	}
}
.contact{
	background-color: $color-brand-coldblue;
	box-sizing: border-box;
	padding: 8% 12%;
	&__feedback{
		padding: 10px 0;
		&--success{
			color: $color-brand-green;
			font-weight: 700;
		}
		&--error{
			color: $color-brand-red;
			font-weight: 700;
		}
	}
	&__prev{
		left: 0;
        height: 0;
		opacity: 0;
        position: absolute;
        top: 0;
        width: 0;
        z-index: -1;
	}
}
.form{
	width: 80%;
}
.blog{
	box-sizing: border-box;
	font-size: 0;
	padding: 50px 10% 10% 10%;
	position: relative;
	&__sidebar{
		box-sizing: border-box;
		display: inline-block;
		padding-right: 5%;
		padding-top: 120px;
		vertical-align: top;
		width: 25%;
	}
	&__back{
		display: inline-block;
		vertical-align: top;
	}
	&__arrow{
		display: none;
		height: 24px;
		width: 24px;
	}
	&__list{
		display: inline-block;
		vertical-align: top;
		width: 75%;
	}
}
.subnav{
	background-color: $color-brand-white;
	font-size: 0;
	&__position{
		left: 0;
		height: 150px;
		position: fixed;
		top: 0;
		width: 0;
	}
	&__sticky{
		box-sizing: border-box;
		margin-left: 0;
		padding-right: 4%;
		position: fixed;
		top: 120px;
		width: 20%;
		z-index: 99;
	}
	&__list{
		padding-top: 65px;
		width: 100%;
	}
	&__item{
		color: $color-brand-black;
		display: block;
		font-weight: 400;
		@include font-size(21px);
		@include line-height(28px);
		padding: 10px 10px;
		transition: color 0.3s;
		&--active{
			color: $color-brand-green;
		}
	}
	&__burger{
		display: none;
	}
}
.case{
	&__head{
		padding-top: 120px;
	}
	&--img{
		border-radius: 8px;
	}
	&__subhead{
		padding-top: 70px;
	}
	&__step{
		font-weight: 600;
		&--orange{
			color: $color-brand-orange;
		}
	}
	&__status{
		border-radius: 10px;
		box-sizing: border-box;
		padding: 20px;
		width: 100%;
		a{
			border-bottom: 2px solid rgba(#F3465A,1);
			color: rgba(#F3465A,1);
			font-weight: 600;
			padding-bottom: 1px;
		}
		&--red{
			background-color: rgba(#FFEAEC,1);
			color: rgba(#F3465A,1);
			border: 2px solid #F3465A;
		}
	}
}

.highlight{
	display: table;
	padding-bottom: 20px;
	width: 100%;
	&__pad{
		padding-bottom: 60px;
	}
	&__padmed{
		padding-bottom: 30px;
	}
	&__last{
		padding-bottom: 0;
	}
	&__bin{
		background-color: $color-brand-coldblue;
		border-radius: 10px;
		box-sizing: border-box;
		display: table-cell;
		padding: 20px;
		position: relative;
		width: 32%;
		p{
			@include font-size(20px);
			@include line-height(28px);
		}
		&__small{
			padding: 10px;
		}
		&--red{
			background-color: #FCE8E3;
			border: 1px solid #FF4A1F;
			padding-left: 54px;
		}
		&--green{
			background-color: #E7FCF3;
			border: 1px solid #0EBA72;
			padding-left: 54px;
		}
		&--teal{
			background-color: rgba(#E2F0E8,1);
		}
	}
	&__space{
		display: table-cell;
		width: 2%;
	}
	&__number{
		border-radius: 50%;
		box-sizing: border-box;
		color: fff;
		display: inline-block;
		height: 25px;
		left: 18px;
		padding-top: 2px;
		position: absolute;
		text-align: center;
		top: 10px;
		width: 25px;
		p{
			@include font-size(16px);
			@include line-height(22px);
		}
		&--red{
			background-color: #FF4A1F;
		}
		&--green{
			background-color: #0EBA72;
		}
	}
	&__mobile{
		display: none;
	}
}

.extend{
	padding-bottom: 500px;
}
