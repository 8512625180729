img{
	border-radius: 10px;
	width: 100%;
	&.stroke{
		border: 1px solid rgba(#CFDADF, 0.8);
		box-sizing: border-box;
	}
}
video{
	border-radius: 10px;
	width: 100%;
}
form{
	padding-top: 5%;
}
input[type=text], input[type=email], input[type=number], textarea { 
	background: none;
	border-bottom: 2px solid $color-brand-black; 
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	border-top: 0;
	box-shadow: none;
	box-sizing: border-box; 
	color: $color-brand-black;
	font-family: $font-main;
	@include font-size(21px);
	font-weight: 400;
	@include line-height(28px);
	margin-bottom: 40px; 
	transition: all 0.3s;
	width: 100%;
	&::placeholder{
		color: $color-brand-grayd;
	}
}
input[type=number]{
	max-width: 200px;
}
textarea{
	border: 2px solid $color-brand-black;
	border-radius: 5px;
	height: 120px;
	padding: 15px; 
	resize: vertical;
}
input:focus{
	border-bottom: 2px solid $color-brand-blue; 
	outline: none;
}
textarea:focus{
	border: 2px solid $color-brand-blue; 
	outline: none;
}
.button {
	background-color: $color-brand-green;
	border: none;
	border-radius: 5px;
	color: $color-brand-white;
	cursor: pointer;
	display: block;
	font-weight: 700;
	outline: none;
	padding: 15px 64px 15px 40px;
	transition: padding 0.4s;
	&__icon{
		position: relative;
	}
	&__arrow{
		height: 24px;
		margin-top: -12px;
		position: absolute;
		right: 32px;
		top: 50%;
		width: 24px;
		svg{
			fill: $color-brand-white;
			height: 100%;
			width: 100%;
		}
	}
}