.hook{
	animation-duration: 1.2s;
	animation-fill-mode: both;
	&__box{
		opacity: 0;
	}
	&__image{
		opacity: 0;
	}
	&__curtain{
		opacity: 0;
	}
}	

@keyframes fade-in {
	from {
		opacity: 0;
  	}
  	to {
		opacity: 1;
  	}
}

@keyframes fade-in-up {
	from {
		opacity: 0;
		transform: translate3d(0, 15%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-left {
	from {
		opacity: 0;
		transform: translate3d(-20%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);  
	}
}

@keyframes fade-in-var {
	from {
		opacity: 0;
		transform: translate3d(5%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-down {
	from {
		opacity: 0;
		transform: translate3d(0, -10%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fade__in {
	animation-name: fade-in;
	&--up{
		animation-name: fade-in-up;
	}
	&--left{
		animation-name: fade-in-left;
	}
	&--var{
		animation-name: fade-in-var;
	}
}

.animated {
	animation-duration: 2s;
	animation-fill-mode: both;
	&__long{
		animation-duration: 5s;
		animation-fill-mode: both;
	}
	&__short{
		animation-duration: .5s;
		animation-fill-mode: both;
	}
}

.animated {
	&__infinite {
		animation-iteration-count: infinite;
	}
}

.delay {
	&__mini{
		animation-delay: 0.1s;
	}
	&__one{
		animation-delay: 0.4s;
	}
	&__two{
		animation-delay: 0.8s;
	}
	&__three{
		animation-delay: 01s;
	}
}