$image-path: "../images/";

$font-main: 'Montserrat', sans-serif;

$color-brand-black: rgba(#222222,1);
$color-brand-green: rgba(#0EBA72,1);
$color-brand-blue: rgba(#2682ED,1);
$color-brand-red: rgba(#2682ED,1);
$color-brand-orange: rgba(#FF4A1F,1);
$color-brand-coldblue: rgba(#eff2f5,1);
$color-brand-white: rgba(#fcfcfc,1);
$color-brand-grayd: rgba(#555555,1);
$color-brand-grayl: rgba(#aaaaaa, 1);
$color-brand-grayf: rgba(#f5f5f5, 1);

$color-social-gmail: rgba(#DB4437,1);
$color-social-dribbble: rgba(#EA4C89,1);
$color-social-linkedin: rgba(#0E76A8,1);
$color-social-twitter: rgba(#00ACED,1);